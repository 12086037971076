<template>
  <div class="container">
    <div class="container-body">
        <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
        <el-row>
            <el-col :span="6">
                <div class="grid-content bg-purple title" style="height:70px;width:128px;">
                    <h3>安全众测</h3>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>赛尔安全众测 是由众多白帽子和自建专业技术团队力量针测试目标系统，在规定时间内利用多种技术手段或漏扫设备进行漏洞悬赏式竞技检测，收到有效的漏洞后按漏洞风险等级给予一定的奖励。</p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content" style="width:50vw;margin:0 auto;">
                   <img src="../../assets/zhongce1.png" alt="" style="width:100%;height:100%;">
                </div>
            </el-col>
        </el-row>
         <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <h4>安全众测的特点包括：</h4>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <p>1. 参与测试的人员数量多 </p>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <p>2. 人员技术水平可考量 </p>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <p>3. 激励机制以结果为导向 </p>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <p>4. 测试效果更好 </p>
                </div>
                <div class="grid-content bg-purple content">
                     <p>5. 成本更优  </p>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <h4>常见的组织方式包括：</h4>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <p>1. 白帽子众测  </p>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <p>2. 多家安全公司参与的众测  </p>
                </div>
            </el-col>
             <el-col :span="24">
                <div class="grid-content bg-purple content">
                     <p>3. 内部众测 </p>
                </div>
            </el-col>
        </el-row>
          <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content" style="margin-bottom:2vw;;width:50vw;margin:0 auto;">
                   <img src="../../assets/zhongce2.png" alt="" style="width:100%;height:100%;">
                </div>
            </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xxx:'\u3000\u3000',
      ClientWidth_number: document.documentElement.clientWidth,
    };
  },
  created() {
    this.getClientWidth();
   
  },
  methods: {
    getClientWidth() {
      window.onresize = () => {
        this.ClientWidth_number = document.documentElement.clientWidth;
      };
    },
  },
};
</script>

<style lang='less' scoped>
@baseWidth: 1980;
.pxfix(@w) {
  width: (@w / @baseWidth) * 100vw;
}
 .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .container-body {
    .pxfix(1980);
    min-height: 700px;
    margin: 0 auto;
    background: #eff3f8;
    // margin-top: 20px;
    display: flex;
    flex: 1;
    .title{
        font-size:1.2rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: normal;
        font-family: "microsoft yahei";
        color: #555;
    }
    .content{
      margin: 0 1rem;
      color: #555;
      font-size:0.8rem;   
      font-family: "microsoft yahei";
      color: #555;
      line-height: 25px;
       }
    .body-box{
        .pxfix(1040);
     margin:1.5rem auto;
    //  flex:1;
    padding: 0 3vw;
    background: #fff;
    }
    .body-box-phone{
        background: #fff;
        margin:1.5rem auto;
    }
     .body-box:hover{
     box-shadow: 0 0  10px #888888;
    }
  }
  .footer {
    line-height: 50px;
    background: #2f3034;
    width: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
</style>
